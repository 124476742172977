<template >
  <b-container fluid>
    <loading :active.sync="isWorkingAdmin" :is-full-page="true"></loading>
    <b-row align="center" align-h="center">
      <b-col><h4 style="color:white;">Acceso Administrador</h4></b-col>
    </b-row>
    <b-row >
      <b-col cols="12" >
        <b-row align-h="center">
          <b-col xl="3" lg="4" md="6" sm="10" cols="12" class="border-login">
            <b-row align-h="center" class="text-center main-row">
              <b-col><b-icon style="color:gray;" font-scale="7" icon="person-circle"/></b-col>
              <b-col cols="12" md="11">
                <b-alert :show="msjErrorLoginAdmin.time" dismissible variant="danger" @dismissed="msjErrorLoginAdmin.time=0" @dismiss-count-down="countDown">{{msjErrorLoginAdmin.msj}}</b-alert>
              </b-col>
            </b-row>
            <b-row align-h="center">
              <b-col cols="12" md="11">
                  <b-form-group label="Usuario:" >
                    <b-form-input  :state="!$v.inTxtUser.$invalid" type="text" v-model.trim="$v.inTxtUser.$model"></b-form-input>
                    <div class="error" v-if="!$v.inTxtUser.minLength">El nombre de usuario es demasiado corto</div>
                    <div class="error" v-if="!$v.inTxtUser.maxLength">El nombre de usuario es demasiado largo</div>
                    <div class="error" v-if="!$v.inTxtUser.required">Este campo es obligatorio</div>
                  </b-form-group>
              </b-col>
            </b-row>
            <b-row align-h="center">
              <b-col cols="12" md="11">
                  <b-form-group label="Contraseña:" >
                    <b-form-input  :state="!$v.inPassUser.$invalid" type="password" v-model.trim="$v.inPassUser.$model"></b-form-input>
                    <div class="error" v-if="!$v.inPassUser.minLength">La Contraseña es demasiado corta</div>
                    <div class="error" v-if="!$v.inPassUser.maxLength">La Contraseña es demasiado larga</div>
                    <div class="error" v-if="!$v.inPassUser.required">Este campo es obligatorio</div>
                    <b-link>
                      <span @click="showModalInstrucciones" class="rec-contrasena-text">Recuperar Contraseña</span>
                    </b-link>
                  </b-form-group>
              </b-col>
            </b-row>
            <b-row align-h="center">
              <b-col cols="12" md="11" >
                <vue-recaptcha ref="recaptchaloginAdmin"  sitekey="6LdD09sUAAAAANCvTN2xMZqvfMOvzkdkOB1Zcpok" @verify="captchaLoginFilled" @expired="captchaLoginExpired" :loadRecaptchaScript="true" />
                <div class="error" v-if="msgErrorCaptcha!=''">{{msgErrorCaptcha}}</div><br>
              </b-col>
            </b-row>
            <b-row align-h="center" class="text-center">
              <b-col cols="12" md="11">
                <b-button block pill size="lg" variant="success" @click="login">Iniciar sesión <b-icon icon="arrow-right-circle"/></b-button>
              </b-col>
            </b-row><br>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- modals -->
    <b-modal v-model="isShowModalInstrucciones" title="Recuperar Contraseña" hide-footer @hidden="cleanFormRecPass()">
        <b-form-group label="Ingrese su USUARIO:">
          <b-form-input :state="!$v.modeloRecuperarContraseña.username.$invalid" v-model.trim="$v.modeloRecuperarContraseña.username.$model"></b-form-input>
          <div class="error" v-if="!$v.modeloRecuperarContraseña.username.minLength">El USUARIO es demasiado corto</div>
          <div class="error" v-if="!$v.modeloRecuperarContraseña.username.maxLength">El USUARIO es demasiado largo</div>
          <div class="error" v-if="!$v.modeloRecuperarContraseña.username.required">Este campo es obligatorio</div>
        </b-form-group>
        <b-form-group label="Ingrese su correo electrónico:">
          <b-form-input :state="!$v.modeloRecuperarContraseña.correo.$invalid" v-model.trim="$v.modeloRecuperarContraseña.correo.$model"></b-form-input>
          <div class="error" v-if="!$v.modeloRecuperarContraseña.correo.email">El correo electrónico es incorrecto</div>
          <div class="error" v-if="!$v.modeloRecuperarContraseña.correo.required">Este campo es obligatorio</div>
        </b-form-group>
        <vue-recaptcha ref="recaptchaRecPassAdmin" sitekey="6LdD09sUAAAAANCvTN2xMZqvfMOvzkdkOB1Zcpok" @verify="captchaRecuPassFilled" @expired="captchaRecuPassExpired" :loadRecaptchaScript="true"/>
        <div class="error" v-if="msgErrorCaptchaPass!=''">{{msgErrorCaptchaPass}}</div><br>
        <b-button block size="lg" variant="primary" @click="sendRestablecerContraseña()">Restablecer contraseña</b-button>
    </b-modal>
  </b-container>
</template>

<script>
  import { minLength, maxLength, required, email } from 'vuelidate/lib/validators';
  import { loginAdmin, recuperarContraseñaAdmin } from '@/axios-tools';
  import { mapState} from 'vuex';
  import VueRecaptcha from 'vue-recaptcha';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    computed: {
      ...mapState({
        isWorkingAdmin: state => state.admin.isWorkingAdmin
      }),
      msjErrorLoginAdmin: {
        get: function() {
          return this.$store.state.admin.msjErrorLoginAdmin;
        },
        set: function(newMsjErrorLogin) {
          this.$store.commit("updateMsjErrorLogin", {msj:newMsjErrorLogin.msj, time:newMsjErrorLogin.time});
        }
      },
    },
    components: {
      Loading,
      VueRecaptcha
    },
    data() {
      return {
        cliente: location.pathname.split("/", 2).toString().replace(",", ""),
        inTxtUser: "",
        inPassUser: "",
        isShowModalInstrucciones: false,
        captchaLogin:null,
        height:window.innerHeight,
        msgErrorCaptcha:'',
        msgErrorCaptchaPass:'',
        modeloRecuperarContraseña:{
          username:"",
          correo:""
        }
      }
    },
    methods: {
      countDown(dismissCountDown){
        this.$store.commit("updateTimeMsjErrorLoginAdmin", dismissCountDown);
      },
      captchaLoginFilled(token){
        this.captchaLogin = token;
        this.msgErrorCaptcha="";
      },
      captchaLoginExpired(){
        this.captchaLogin = null;
      },      
      captchaRecuPassExpired(){
        this.captchaRecuPass = null;
      },
      captchaRecuPassFilled(token){
        this.captchaRecuPass = token;
        this.msgErrorCaptchaPass='';
      },
      sendRestablecerContraseña(){
        if(this.captchaRecuPass != null && this.captchaRecuPass != ''){
          recuperarContraseñaAdmin(this.modeloRecuperarContraseña.username, this.modeloRecuperarContraseña.correo, this.$route.params.municipio, this.$store, this.captchaRecuPass);
          this.cleanFormRecPass();
          this.$refs.recaptchaRecPassAdmin.reset();
          this.captchaRecuPass = null;
        }else{
          this.msgErrorCaptchaPass="Por favor complete el captcha";
        }
        this.isShowModalInstrucciones = false;
      },
      cleanFormRecPass(){
        this.modeloRecuperarContraseña.username="";
        this.modeloRecuperarContraseña.correo="";
      },
      login(){
        if(!this.$v.inTxtUser.$invalid && !this.$v.inPassUser.$invalid){
          if(this.captchaLogin!=null && this.captchaLogin!=""){
            loginAdmin(this.$store, this.$router, this.inTxtUser, this.inPassUser, this.cliente, this.captchaLogin);
            this.inTxtUser='';
            this.inPassUser='';
            this.$refs.recaptchaloginAdmin.reset();
            this.captchaLogin = null;
          }else{
            this.msgErrorCaptcha="Por favor completa el captcha";
          }
        }
      },
      showModalInstrucciones(){
        this.isShowModalInstrucciones = true;
      }
    },
    validations: {
        inTxtUser:{
            required,
            minLength: minLength(4),
            maxLength: maxLength(20)
        },
        inPassUser:{
            required,
            minLength: minLength(4),
            maxLength: maxLength(12)
        },
        modeloRecuperarContraseña:{
          username:{
            required,
            minLength: minLength(3),
            maxLength: maxLength(15)
          },
          correo:{
            email,
            required
          }
        }
    }
  }
</script>
<style>
.input{
  background-color: rgba(38, 38, 38, 0.7);
  color:#FFFFFF;
}
.rec-contrasena-text{
  color:#6AD907;
}
.input-label{
  color:#FFFFFF;
}
div.error{
  color: red;
}
.main-row{
  margin-top:30px;
}
</style>